<template>
  <div>
    <section-banner :model="section"></section-banner>
    <h6 class="text-caption text-center mt-n12"></h6>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-container class="mt-12 mb-12">
      <v-row class="d-flex justify-center">
        <v-col cols="12" xl="3" lg="3" md="6" sm="12" xs="12" v-for="(vacancy, i) in vacancies" :key="i">
          <vacancy-card class=" flex-grow-1" :vacancy="vacancy"></vacancy-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-banner elevation="3">
        <h3 class="primary__text text-center">Please email applications to <a href="mailto:accounts@NLAteam.com">accounts@NLAteam.com</a></h3>
      </v-banner>
    </v-container>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import SectionBanner from "@/components/Frontend/SectionBanner";
import VacancyCard from "@/components/Frontend/Vacancies/VacancyCard";
import LoginRegister from "@/components/Frontend/LoginRegister";

export default {
  components: {
    SectionBanner,
    VacancyCard,
    LoginRegister,
  },
  data() {
    return {
      showLoginState: false,
      vacancies: [
        {
          title: "Candidate Attorney",
          reportingLine: "Senior Attorneys",
          location: "Johannesburg",
          description: "NLA is in need of a candidate attorney who would live our core values while learning how to be the best attorney they could possibly be. We need someone who demonstrates a great work ethic, critical thinking, initiative and can work in a team.",
          closingDate: "Friday, 17 February 2023",
        },
      ],
      totalRecords: 0,
      isLoading: false,
      productToAdd: "",
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      section: {
        BannerSmall: true,
        Title: "Job Vacancies",
        Subtitle: "Join the NLA team",
        HTMLDescription: "",
        HTMLCaption: "",
        img: require("@/assets/img/background.jpg"),
      },
    };
  },
  created() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
}
</style>
