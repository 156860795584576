<template>
  <v-hover v-slot:default="{ hover }">
    <v-card color="grey lighten-4">
      <v-img :aspect-ratio="16 / 9" src="@/assets/img/legal documents.jpg"> </v-img>
      <v-card-text class="pt-8" style="position: relative;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn absolute class="mr-1 secondary--text cart__btn" fab right top @click="openPreview('/documents/role_profile_candidate_attorney.pdf')" v-on="on">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>View Role Profile </span>
        </v-tooltip>
        <h3 @click="alert('asd')" class="text-xl-h5 font-weight-light primary__text mb-2 pointer">
          <strong>{{ vacancy.title }}</strong>
        </h3>
        <div class="font-weight-light grey--text title mb-2" v-html="vacancy.description" />
      </v-card-text>
      <v-divider />
      <v-card-text class="">
        <h3 v-if="vacancy.reportingLine" class="text-caption mb-2">
          <strong>Location: </strong>
          <v-chip small><div v-html="vacancy.location"></div></v-chip>
        </h3>
        <h3 v-if="vacancy.reportingLine" class="text-caption mb-2">
          <strong>Reporting Line: </strong>
          <v-chip small><div v-html="vacancy.reportingLine"></div></v-chip>
        </h3>
        <h3 v-if="vacancy.reportingLine" class="text-caption mb-2">
          <strong>Application Closing Date: </strong>
          <v-chip small><div v-html="vacancy.closingDate"></div></v-chip>
        </h3>
        <h3 v-if="vacancy.reportingLine" class="text-caption mb-2">
          <strong>View The Full Job Role Profile: </strong>
          <v-chip small @click="openPreview('/documents/role_profile_candidate_attorney.pdf')"><div v-html="'Click here to download'"></div></v-chip>
        </h3>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    vacancy: {
      type: Object,
      default: {
        title: "",
        reportingLine: "",
        location: "",
        description: "",
      },
    },
  },
  data() {
    return {
      me: null,
    };
  },
  methods: {
    openPreview(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/scss/_variables.scss";
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  flex-wrap: wrap;
}
.pointer {
  cursor: pointer;
}

.inc-vat {
  font-size: 16px;
  justify-self: center;
}
</style>
